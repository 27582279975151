<div class="background d-flex align-items-center justify-content-center">
  <div *ngIf="isDesktop">
    <img class="img-responsive-home" [src]="sliderhomeDesktop" alt="">
  </div>
  <div *ngIf="!isDesktop">
    <img class="img-responsive-home" [src]="sliderhome" alt="">
  </div>

  <div class="d-flex container align-items-center justify-content-around busca" [ngClass]="active ? 'viewInit' : ''">
    <div class="card d-flex justify-content-between">
      <div class="card-header d-flex justify-content-around">
        <div *ngIf="usaAereo" class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="aereo" [(ngModel)]="busca.tipoBusca.aereo"
            [checked]="busca.tipoBusca.aereo">
          <label class="form-check-label" for="aereo">
            <i class="fas fa-plane-departure text-secondary"></i>
            {{"Aéreo" | translate}}
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="hotel" [(ngModel)]="busca.tipoBusca.hotel"
            [checked]="busca.tipoBusca.hotel">
          <label class="form-check-label" for="hotel">
            <i class="fas fa-bed text-secondary"></i>
            {{"Hotel" | translate}}
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="servico" [(ngModel)]="busca.tipoBusca.ticket"
            [checked]="busca.tipoBusca.ticket">
          <label class="form-check-label" for="servico">
            <i class="fas fa-shuttle-van text-secondary"></i>
            {{"Serviço" | translate}}
          </label>
        </div>
        <div *ngIf="usaCarro" class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="carro" [(ngModel)]="busca.tipoBusca.carro"
            [checked]="busca.tipoBusca.carro">
          <label class="form-check-label" for="carro">
            <i class="fas fa-car text-secondary"></i>
            {{"Carro" | translate}}
          </label>
        </div>
        <div *ngIf="usaBetoCarrero" class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="beto" [(ngModel)]="busca.tipoBusca.beto" [checked]="busca.tipoBusca.beto">
          <label class="form-check-label" for="beto">
            <i class="fa fa-hat-cowboy-side text-secondary"></i>
            {{"Beto Carrero" | translate}}
          </label>
        </div>
      </div>

      <div class="card-header d-flex justify-content-between" *ngIf="busca.tipoBusca.aereo">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="inlineRadio1" (click)="setUmTrecho(); tipoAereo('ida-volta')"
            [value]="'ida-volta'" [checked]="busca.tipoBuscaAereo == 'ida-volta'">
          <label class="form-check-label" for="inlineRadio1">{{"Ida e Volta" | translate}}</label>
        </div>
        <div class="form-check form-check-inline" *ngIf="somenteida">
          <input class="form-check-input" type="radio" id="inlineRadio2" (click)="setUmTrecho(); tipoAereo('ida')"
            [value]="'ida'" [checked]="busca.tipoBuscaAereo == 'ida'">
          <label class="form-check-label" for="inlineRadio2">{{"Somente Ida" | translate}}</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="inlineRadio3" (click)="setUmTrecho(); tipoAereo('trechos')"
            [value]="'multiplos-trechos'" [checked]="busca.tipoBuscaAereo == 'multiplos-trechos'">
          <label class="form-check-label" for="inlineRadio3">{{"Multiplos Trechos" | translate}}</label>
        </div>
      </div>

      <section class="aereoActive" *ngIf="busca.tipoBusca.aereo">
        <div class="card-body overflow-auto">
          <div class="trechos" *ngFor="let trecho of busca.trechos; let i = index">
            <h6 class="mb-3" *ngIf="busca.tipoBuscaAereo == 'multiplos-trechos'">{{"Trecho" | translate}} {{i +1}}</h6>
            <div class="d-flex gap-3 mb-3">
              <div class="form-floating">
                <app-typeahead class="form-floating" [tap]="false" name="cidadepartida" [lista]="cidades"
                  [searchText]="trecho.AeroportoPartida.display_field" (searchFunction)="buscaDados($event)"
                  (selectItem)="selecionaCidadeOrigem($event, trecho)">
                  <input class="form-control" type="text" placeholder="{{'Aeroporto de Partida' | translate}}"
                    [(ngModel)]="trecho.AeroportoPartida.display_field" (ngModelChange)="buscaCidadeDebounce($event)"
                    autocomplete="off" />
                  <label for="floatingInput">{{"Origem" | translate}}</label>
                </app-typeahead>
              </div>
              <div class="form-floating">
                <app-typeahead class="form-floating" [tap]="false" name="cidadechegada" [lista]="cidades"
                  [searchText]="trecho.AeroportoChegada.display_field" (searchFunction)="buscaDados($event)"
                  (selectItem)="selecionaCidadeDestino($event, trecho)">
                  <input class="form-control" type="text" placeholder="{{'Aeroporto de Chegada' | translate}}"
                    [(ngModel)]="trecho.AeroportoChegada.display_field" (ngModelChange)="buscaCidadeDebounce($event)"
                    autocomplete="off" />
                  <label for="floatingInput">{{"Destino" | translate}}</label>
                </app-typeahead>
              </div>
            </div>
            <div class="d-flex gap-3 mb-3 mDt-ida-volta">
              <div class="form-floating">
                <input type="date" class="form-control" id="ida" onKeyDown="return false" [(ngModel)]="trecho.dataIda"
                  (ngModelChange)="setDataIda($event, busca.trechos, i)" [min]="dataAtual" />
                <label for="floatingInput">{{"Data de Ida" | translate}}</label>
              </div>
              <div class="d-flex align-items-center" *ngIf="busca.tipoBuscaAereo == 'multiplos-trechos'">
                <i class="fas fa-plus-circle text-primary fs-5" *ngIf="i >= qntTrechos && qntTrechos < 5"
                  (click)="adicionarTrecho()"></i>
                <i class="fas fa-minus-circle text-primary fs-5" *ngIf="i < qntTrechos" (click)="removerTrecho(i)"></i>
                <p style="margin: 0" *ngIf="i == qntTrechos && qntTrechos == 5">Max</p>
              </div>
              <div class="form-floating" *ngIf="busca.tipoBuscaAereo == 'ida-volta'">
                <select class="form-select" aria-label="Default select example" id="noites" [(ngModel)]="busca.nrnoites"
                  (ngModelChange)="onChangeNoites($event, busca.trechos, i)">
                  <option *ngFor="let i of [].constructor(30); let a = index" value="{{ a }}">{{ a }}</option>
                </select>
                <label for="floatingInput">{{"Noites" | translate}}</label>
              </div>
              <div class="form-floating" *ngIf="busca.tipoBuscaAereo == 'ida-volta'">
                <input type="date" class="form-control" id="ida" [(ngModel)]="trecho.dataVolta" [min]="trecho.dataIda"
                  onKeyDown="return false" (ngModelChange)="onChangeVolta($event, busca.trechos, i)"
                  [min]="dataAtual && trecho.dataIda" />
                <label for="floatingInput">{{"Data de Volta" | translate}}</label>
              </div>
            </div>

            <app-typeahead class="row form-floating" style="margin-left: 2px; margin-bottom: 5px;" name="cidadepartida"
              [lista]="paises" [searchText]="busca.nacionalidadePax.Nome" (searchFunction)="buscaPais($event)"
              (selectItem)="selecionaPaisNacionalidade($event)">
              <input class="form-control" type="text" placeholder="Nacionalidade do pax"
                [(ngModel)]="busca.nacionalidadePax.Nome" (ngModelChange)="buscaPaisDebounce($event)" autocomplete="off"
                name="paisNacionalidade" />
              <label for="floatingInput">{{"Nacionalidade" | translate}}</label>
            </app-typeahead>
          </div>
          <div class="d-grid gap-3 mb-3">
            <button type="button" class="btn btn-outline-primary" *ngIf="!adicionar" data-bs-toggle="modal"
              data-bs-target="#modalViajantes" (click)="limpaPesquisa()">
              {{busca.getQtdeAdultos() + busca.getQtdeChd()}} {{"Passageiro(s)" | translate}} /
              {{busca.getQtdeQuartos()}} {{"Quarto(s)" | translate}}
            </button>
          </div>
          <div class="d-flex gap-3 justify-content-between">
            <!-- <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="promocoes">
              <label class="form-check-label" for="promocoes">
                {{"Apenas Promoções" | translate}}
              </label>
            </div> -->


            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="bagagem"
                [(ngModel)]="busca.opcoesAvancadas.TodasFamilias">
              <label class="form-check-label" for="bagagem">
                {{"Somente voos com bagagem" | translate}}
              </label>
            </div>


            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="voo_direto"
                [(ngModel)]="busca.opcoesAvancadas.diretos">
              <label class="form-check-label" for="voo_direto">
                {{"Voo Direto" | translate}}
              </label>
            </div>
          </div>
        </div>
      </section>

      <section class="hotelOrServiceActive"
        *ngIf="!busca.tipoBusca.aereo && (busca.tipoBusca.hotel || busca.tipoBusca.ticket)">
        <div class="card-body">
          <div class="trechos" *ngFor="let trecho of busca.trechos; let i = index">
            <h6 class="mb-3" *ngIf="busca.tipoBuscaAereo == 'multiplos-trechos'">Trecho {{i +1}}</h6>
            <div class="d-flex gap-3 mb-3">
              <div class="form-floating">
                <app-typeahead class="form-floating" [tap]="true" name="cidadechegada" [lista]="cidades"
                  [searchText]="trecho.cidadeDestino.display_field" (searchFunction)="buscaDados($event)"
                  (selectItem)="selecionaCidadeDestino($event, trecho)">
                  <input class="form-control" type="text" placeholder="{{'Cidade de Destino' | translate}}"
                    [(ngModel)]="trecho.cidadeDestino.display_field" (ngModelChange)="buscaCidadeDebounce($event)"
                    autocomplete="off" />
                  <label for="floatingInput">{{"Destino" | translate}}</label>
                </app-typeahead>
              </div>
            </div>
            <div class="d-flex gap-3 mb-3 div_dias">
              <div class="form-floating">

                <input type="date" class="form-control" id="ida" [(ngModel)]="trecho.dataIda"
                  (ngModelChange)="setDataIda($event, busca.trechos, i)" [min]="dataAtual" />
                <label for="floatingInput">{{"Checkin" | translate}}</label>
              </div>
              <div class="form-floating">
                <select class="form-select" aria-label="Default select example" id="noites" [(ngModel)]="busca.nrnoites"
                  (ngModelChange)="onChangeNoites($event, busca.trechos, i)">
                  <option *ngFor="let i of [].constructor(30); let a = index" value="{{ a }}">{{ a }}</option>
                </select>
                <label for="floatingInput">{{"Noites" | translate}}</label>
              </div>
              <div class="form-floating">

                <input type="date" class="form-control" id="ida" [(ngModel)]="trecho.dataVolta" [min]="trecho.dataIda"
                  (ngModelChange)="onChangeVolta($event, busca.trechos, i)" [min]="dataAtual && trecho.dataIda" />
                <label for="floatingInput">{{"Checkout" | translate}}</label>
              </div>
              <div class="form-floating" *ngIf="busca.tipoBusca.beto">
                <input type="date" class="form-control" id="visita" onKeyDown="return false"
                  [min]="dataAtual" [(ngModel)]="busca.dataVisitaBetoCarrero" (ngModelChange)="setaDataBeto($event, busca.dataVisitaBetoCarrero)"/>
                <label for="floatingInput">{{"Data de visita" | translate}} (Beto Carrero)</label>
              </div>
              <app-typeahead class="form-floating" style="margin-left: 2px; margin-bottom: 5px;" name="cidadepartida"
                [lista]="paises" [searchText]="busca.nacionalidadePax.Nome" (searchFunction)="buscaPais($event)"
                (selectItem)="selecionaPaisNacionalidade($event)">
                <input class="form-control" type="text" placeholder="{{'Nacionalidade do' | translate}} PAX"
                  [(ngModel)]="busca.nacionalidadePax.Nome" (ngModelChange)="buscaPaisDebounce($event)"
                  autocomplete="off" name="paisNacionalidade" />
                <label for="floatingInput">{{"Nacionalidade" | translate}}</label>
              </app-typeahead>
            </div>
          </div>
          <div class="d-grid gap-3 mb-3">
            <button type="button" class="btn btn-outline-primary" *ngIf="!adicionar" data-bs-toggle="modal"
              data-bs-target="#modalViajantes">
              {{busca.getQtdeAdultos() + busca.getQtdeChd()}}{{" Passageiro(s) / " | translate}}
              {{busca.getQtdeQuartos()}}{{" Quarto(s)" | translate}}
            </button>
          </div>
          <div class="d-flex gap-3 justify-content-between div_pbv mob-wrap">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="promocoes">
              <label class="form-check-label" for="promocoes">
                {{"Apenas Promoções" | translate}}
              </label>
            </div>
            <div class="form-check form-check-inline" style="display: none">
              <input class="form-check-input" type="checkbox" id="bagagem">
              <label class="form-check-label" for="bagagem">
                {{"Bagagem" | translate}}
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="voo_direto">
              <label class="form-check-label" for="voo_direto">
                {{"Voo Direto"}}
              </label>
            </div>
          </div>
        </div>
      </section>

      <section class="betoActive"
        *ngIf="!busca.tipoBusca.aereo && !busca.tipoBusca.hotel && !busca.tipoBusca.ticket && !busca.tipoBusca.carro && busca.tipoBusca.beto">
        <div class="card-body overflow-auto">

          <div class="d-grid gap-3 mb-3">

            <div class="form-floating">
              <div class="form-floating">
                <input [min]="" type="date" class="form-control" id="visita" onKeyDown="return false"
                  [(ngModel)]="busca.dataVisitaBetoCarrero" />
                <label for="floatingInput">{{"Data de visita" | translate}} (Beto Carrero)</label>
              </div>
            </div>
          </div>

          <div class="d-grid gap-3 mb-3">
            <button type="button" class="btn btn-outline-primary" *ngIf="!adicionar" data-bs-toggle="modal"
              data-bs-target="#modalViajantes" (click)="limpaPesquisa()">
              {{busca.getQtdeAdultos() + busca.getQtdeChd()}}{{" Passageiro(s) / " | translate}}
              {{busca.getQtdeQuartos()}}{{" Quarto(s)" | translate}}
            </button>
          </div>
        </div>
      </section>

      <section class="carroActive"
        *ngIf="!busca.tipoBusca.aereo && !busca.tipoBusca.hotel && !busca.tipoBusca.ticket && busca.tipoBusca.carro && !busca.tipoBusca.beto">
        <div class="card-body overflow-auto">

          <!-- Parte Carro Retirada -->
          <div class="d-grid gap-3 mb-3">

            <div class="form-floating">
              <app-typeahead class="form-floating" [tap]="false" name="LocalRetirada" [lista]="lojas"
                [searchText]="busca.localOrigem.display_field" (searchFunction)="buscaLojas($event)"
                (selectItem)="selecionaLojaOrigem($event)">
                <input class="form-control" type="text" placeholder="{{'Origem' | translate}}"
                  [(ngModel)]="busca.localOrigem.display_field" (ngModelChange)="buscaLojasDebounce($event)"
                  autocomplete="new-password" />
                <label for="floatingInput">{{"Local de Retirada" | translate}}</label>
              </app-typeahead>
            </div>
          </div>

          <div class="d-flex gap-3 mb-3">
            <div class="form-floating">
              <input type="date" class="form-control" id="ida" onKeyDown="return false" [(ngModel)]="busca.dataOrigem"
                (ngModelChange)="setDataOrigem($event)" [min]="minDate" />
              <label for="floatingInput">{{"Data de Ida" | translate}}</label>
            </div>
            <div class="form-floating w-100">
              <select class="form-control" name="horaOrigem" id="horaOrigem" [(ngModel)]="busca.horaOrigem">
                <option value="0">00</option>
                <option value="1">01</option>
                <option value="2">02</option>
                <option value="3">03</option>
                <option value="4">04</option>
                <option value="5">05</option>
                <option value="6">06</option>
                <option value="7">07</option>
                <option value="8">08</option>
                <option value="9">09</option>
                <option selected="" value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
              </select>
              <label for="floatingInput">{{"Hora Retirada" | translate}}</label>
            </div>
            <div class="form-floating w-100">
              <select class="form-control" name="minutoOrigem" id="minutoOrigem" [(ngModel)]="busca.minutoOrigem">
                <option selected="" value="0">00</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
              <label for="floatingInput">{{"Minuto Retirada" | translate}}</label>
            </div>
          </div>

          <!-- Parte Carro Devolução -->
          <div class="d-grid gap-3 mb-3">

            <div class="form-floating">
              <app-typeahead class="form-floating" [tap]="false" name="LocalRetirada" [lista]="lojas"
                [searchText]="busca.localDevolucao.display_field" (searchFunction)="buscaLojas($event)"
                (selectItem)="selecionaLojaDevolucao($event)">
                <input class="form-control" type="text" placeholder="{{'Origem' | translate}}"
                  [(ngModel)]="busca.localDevolucao.display_field" (ngModelChange)="buscaLojasDebounce($event)"
                  autocomplete="new-password" />
                <label for="floatingInput">{{"Local de Devolução" | translate}}</label>
              </app-typeahead>
            </div>
          </div>

          <div class="d-flex gap-3 mb-3">
            <div class="form-floating">
              <input type="date" class="form-control" id="ida" onKeyDown="return false"
                [(ngModel)]="busca.dataDevolucao" (ngModelChange)="setDataDevolucao($event)" [min]="busca.dataOrigem" />
              <label for="floatingInput">{{"Data de Devolução" | translate}}</label>
            </div>
            <div class="form-floating w-100">
              <select class="form-control" name="horaDevolucao" id="horaDevolucao" [(ngModel)]="busca.horaDevolucao">
                <option value="0">00</option>
                <option value="1">01</option>
                <option value="2">02</option>
                <option value="3">03</option>
                <option value="4">04</option>
                <option value="5">05</option>
                <option value="6">06</option>
                <option value="7">07</option>
                <option value="8">08</option>
                <option value="9">09</option>
                <option selected="" value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
              </select>
              <label for="floatingInput">{{"Hora Devolução" | translate}}</label>
            </div>
            <div class="form-floating w-100">
              <select class="form-control" name="minutoDevolucao" id="minutoDevolucao"
                [(ngModel)]="busca.minutoDevolucao">
                <option selected="" value="0">00</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </select>
              <label for="floatingInput">{{"Minuto Devolução" | translate}}</label>
            </div>
          </div>

          <div class="d-grid gap-3 mb-3">
            <button type="button" class="btn btn-outline-primary" *ngIf="!adicionar" data-bs-toggle="modal"
              data-bs-target="#modalViajantes" (click)="limpaPesquisa()">
              {{busca.getQtdeAdultos() + busca.getQtdeChd()}}{{" Passageiro(s) / " | translate}}
              {{busca.getQtdeQuartos()}}{{" Quarto(s)" | translate}}
            </button>
          </div>

        </div>
      </section>

      <section
        *ngIf="!busca.tipoBusca.aereo && !busca.tipoBusca.hotel && !busca.tipoBusca.ticket && !busca.tipoBusca.carro && !busca.tipoBusca.beto">
        <div class="card-body d-flex justify-content-center align-items-center">
          <div class="alert alert-danger" role="alert">
            {{"Você precisa selecionar algum dos itens!" | translate}}
          </div>
        </div>
      </section>

      <div class="card-footer d-flex justify-content-between">
        <button type="reset" class="btn btn-outline-primary" *ngIf="!adicionar"
          (click)="setUmTrecho(); limpaPesquisa()">{{"Limpar Busca" | translate}}</button>
        <button type="reset" class="btn btn-primary" type="submit" (click)="verificaAgencia()"
          *ngIf="busca.tipoBusca.aereo || busca.tipoBusca.hotel || busca.tipoBusca.ticket || busca.tipoBusca.carro"
          [disabled]="((!busca.tipoBusca.hotel && !busca.tipoBusca.ticket) && usuario.EmpresaId == null) && !permiteAgenciaBuscarSomenteAereo">
          {{"Buscar" | translate}}
        </button>
        <button type="reset" class="btn btn-primary" type="submit" (click)="verificaAgencia()" (click)="buscarBeto()"
          *ngIf="busca.tipoBusca.beto && !busca.tipoBusca.aereo && !busca.tipoBusca.hotel && !busca.tipoBusca.ticket && !busca.tipoBusca.carro"
          [disabled]="(!busca.tipoBusca.beto) && usuario.EmpresaId == null">
          <a>{{"Buscar Beto" | translate}}</a></button>
      </div>
    </div>

    <div class="frases">
      <h3>{{ fraselogin | translate }}</h3>
      <h5>{{ frasehome | translate }}</h5>
    </div>
  </div>
</div>

<div class="modal fade" tabindex="-1" id="modalViajantes" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{"Viajantes" | translate}}</h5>
      </div>
      <div class="modal-body" *ngFor="let quarto of busca.quartos; let i = index">
        <div class="container">
          <div class="room">
            <h6>{{"Quarto" | translate}} {{ i + 1 }}</h6>
            <div class="d-flex justify-content-between">
              <p>{{"Adultos" | translate}}</p>
              <div class="d-flex align-items-center">
                <i class="fas fa-minus-circle text-secondary" (click)="quarto.removerAdulto()"></i>
                <p style="margin: 0 12px;">{{ quarto.qtdeAdultos }}</p>
                <i class="fas fa-plus-circle text-secondary" (click)="quarto.adicionarAdulto()"></i>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <p>{{"Crianças" | translate}}</p>
              <div class="d-flex align-items-center">
                <i class="fas fa-minus-circle text-secondary" (click)="quarto.removerCrianca()"></i>
                <p style="margin: 0 12px;">{{ quarto.qtdeCriancas }}</p>
                <i class="fas fa-plus-circle text-secondary" (click)="quarto.adicionarCrianca()"></i>
              </div>
            </div>
            <div class="d-flex justify-content-between"
              *ngFor="let idadeCriancas of quarto.idadesCriancas; let x = index">
              <p>{{ "Idade Criança " + (x + 1) | translate }}</p>
              <div class="d-flex align-items-center">
                <select class="form-control input-sm" name="idades-{{x}}" [(ngModel)]="quarto.idadesCriancas[x]">
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary" *ngIf="busca.quartos.length > 1"
          (click)="decrementaQuartos()">
          {{ "Remover Quarto" | translate }}
        </button>
        <button type="button" class="btn btn-outline-primary" (click)="incrementaQuartos()">
          {{ "Adicionar Quarto" | translate }}
        </button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
          Ok
        </button>
      </div>
    </div>
  </div>
</div>
